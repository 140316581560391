// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.monitoring-main-div {
  display: block;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
}

.monitoring-loading-div {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 50vh;
}

.monitoring-loading-div .spinner-border {
  border-width: 0.25em;
  border-color: #FFED38 #FFED38 #FFED38 transparent;
}

.monitoring-loading-div .spinner-border::before {
  border-top-color: #FFED38;
}

.monitoring-info-cards {
  position: absolute;
  top: 50;
  width: 100%;
  z-index: 10;
}

.map-component {
  position: relative;
  z-index: 1;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .monitoring-info-cards {
    position: relative;
    z-index: auto;
    top: auto;
    left: auto;
  }

  .map-component {
    position: relative;
    z-index: auto;
    height: 90vh;
    overflow: hidden;
  }
}`, "",{"version":3,"sources":["webpack://./src/views/Monitoring/Monitoring.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,oBAAoB;EACpB,iDAAiD;AACnD;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,OAAO;EACP,WAAW;EACX,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,UAAU;AACZ;;AAEA,oCAAoC;AACpC;EACE;IACE,kBAAkB;IAClB,aAAa;IACb,SAAS;IACT,UAAU;EACZ;;EAEA;IACE,kBAAkB;IAClB,aAAa;IACb,YAAY;IACZ,gBAAgB;EAClB;AACF","sourcesContent":[".monitoring-main-div {\n  display: block;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n  position: relative;\n}\n\n.monitoring-loading-div {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n  padding-top: 50vh;\n}\n\n.monitoring-loading-div .spinner-border {\n  border-width: 0.25em;\n  border-color: #FFED38 #FFED38 #FFED38 transparent;\n}\n\n.monitoring-loading-div .spinner-border::before {\n  border-top-color: #FFED38;\n}\n\n.monitoring-info-cards {\n  position: absolute;\n  top: 50;\n  width: 100%;\n  z-index: 10;\n}\n\n.map-component {\n  position: relative;\n  z-index: 1;\n}\n\n/* Media query for smaller screens */\n@media (max-width: 768px) {\n  .monitoring-info-cards {\n    position: relative;\n    z-index: auto;\n    top: auto;\n    left: auto;\n  }\n\n  .map-component {\n    position: relative;\n    z-index: auto;\n    height: 90vh;\n    overflow: hidden;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
