import { Circle, Marker } from "@react-google-maps/api";
import parkingIcon from "../assets/parkingMarker.png";
import React from "react";

const StationClusterMarker = ({
  clusterer,
  position,
  stationId,
  stationName,
  geoFenceRadiusM,
}) => {
  const stationOnLoad = (polygon) => {
    polygon.setOptions({ fillColor: "#FFC300", strokeColor: "#FF5733" });
  };

  return (
    <React.Fragment>
      <Marker
        position={{
          lat: parseFloat(position.lat),
          lng: parseFloat(position.lng),
        }}
        title={
          "Station Name: " +
          stationName +
          "\n" +
          "Station ID: " +
          stationId +
          "\n" +
          "Station Radius: " +
          geoFenceRadiusM
        }
        icon={parkingIcon}
        clusterer={clusterer} // default from the library
      />
      <Circle
        center={{
          lat: parseFloat(position.lat),
          lng: parseFloat(position.lng),
        }}
        radius={geoFenceRadiusM}
        onLoad={stationOnLoad}
        strokeOpacity={0.8}
        strokeWeight={2}
        fillOpacity={0.35}
      />
    </React.Fragment>
  );
};

export default StationClusterMarker;
