import { MarkerClusterer } from "@react-google-maps/api";
import React from "react";
import StationClusterMarker from "./StationClusterMarker";

const StationCluster = ({ stations }) => {
  return (
    <MarkerClusterer>
      {(
        clusterer
      ) =>
        stations.length > 0 &&
        stations.map((station) => (
          <StationClusterMarker
            position={{
              lat: station.lat,
              lng: station.lng,
            }}
            clusterer={clusterer}
            key={station.stationId}
            stationId={station.stationId}
            stationName={station.stationName}
            geoFenceRadiusM={station.geoFenceRadiusM}
          />
        ))
      }
    </MarkerClusterer>
  );
};

export default StationCluster;
