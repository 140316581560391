import React from "react";
import { Card, CardGroup } from "react-bootstrap";

const MonitoringInfoCards = ({
  city,
  // lastUpdateDuration,
  stationCount,
  vehicleCount,
  lastUpdatedAt,
  isShowStations,
  isShowVehicles,
  setIsShowStations,
  setIsShowVehicles,
  vehicleInUseCount,
  isShowInUseVehicles,
  setIsShowInUseVehicles,
}) => {
  const cardStyle = {
    margin: "5px",
    border: "1px solid lightGray",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  };

  const stationCardStyle = {
    ...cardStyle,
    backgroundColor: `${isShowStations ? "" : "lightGray"}`,
    cursor: "pointer",
  };

  const vehicleCardStyle = {
    ...cardStyle,
    backgroundColor: `${isShowVehicles ? "" : "lightGray"}`,
    cursor: "pointer",
  };

  const vehicleInUseCardStyle = {
    ...cardStyle,
    backgroundColor: `${isShowInUseVehicles ? "" : "lightGray"}`,
    cursor: "pointer",
  };

  return (
    <CardGroup>
      <Card style={cardStyle}>
        <Card.Body>
          <Card.Title>{city || "n/a"}</Card.Title>
          <Card.Text>Zone</Card.Text>
        </Card.Body>
        <Card.Footer>
          <small className="text-muted">{lastUpdatedAt}</small>
        </Card.Footer>
      </Card>
      <Card
        style={stationCardStyle}
        onClick={() => setIsShowStations(!isShowStations)}
      >
        <Card.Body>
          <Card.Title>{stationCount || 0}</Card.Title>
          <Card.Text>Stations</Card.Text>
        </Card.Body>
        <Card.Footer>
          <small className="text-muted">{lastUpdatedAt}</small>
        </Card.Footer>
      </Card>
      <Card
        style={vehicleCardStyle}
        onClick={() => setIsShowVehicles(!isShowVehicles)}
      >
        <Card.Body>
          <Card.Title>{vehicleCount || 0}</Card.Title>
          <Card.Text>Total Vehicles</Card.Text>
        </Card.Body>
        <Card.Footer>
          <small className="text-muted">
            {/* Last updated {lastUpdateDuration} mins ago */}
            {lastUpdatedAt}
          </small>
        </Card.Footer>
      </Card>
      <Card
        style={vehicleInUseCardStyle}
        onClick={() => setIsShowInUseVehicles(!isShowInUseVehicles)}
      >
        <Card.Body>
          <Card.Title>{vehicleInUseCount || 0}</Card.Title>
          <Card.Text>Vehicles In Use</Card.Text>
        </Card.Body>
        <Card.Footer>
          <small className="text-muted">{lastUpdatedAt}</small>
        </Card.Footer>
      </Card>
    </CardGroup>
  );
};

export default MonitoringInfoCards;
