import React, { useEffect, useState } from "react";
import "./Login.css";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
  Row,
} from "react-bootstrap";
import trykeIcon from "../../assets/trykeRoundLogo.png";
import { CiUser } from "react-icons/ci";
import { CiLock } from "react-icons/ci";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

const Login = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [hasClicked, setHasClicked] = useState(false);
  const [errorText, setErrorText] = useState("");
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const SERVER_VERSION = process.env.REACT_APP_SERVER_VERSION;
  const APP_SOURCE = process.env.REACT_APP_SOURCE;

  useEffect(() => {
    if (username !== "") {
      setErrorText("");
    }
    if (password !== "") {
      setErrorText("");
    }
  }, [username, password]);

  const loginClicked = () => {
    if (username === "") {
      setErrorText("Invalid Username!");
      return;
    }
    if (password === "") {
      setErrorText("Invalid Password!");
      return;
    }

    setHasClicked(true);
    setTimeout(() => {
      setHasClicked(false);
      setErrorText("");
    }, 5000);

    const payload = {
      id: username,
      pw: password,
      version: SERVER_VERSION,
      source: APP_SOURCE,
    };

    axios
      .post(BASE_URL + "authLogin", payload)
      .then(function (response) {
        login(response.data);
        navigate("/monitoring");
      })
      .catch(function (error) {
        console.log(error);
        setErrorText(
          "😔 Invalid login credential! Please wait 5 seconds to retry!"
        );
      });
  };

  return (
    <div className="login-main-div app flex-row align-items-center">
      <Container className="m-auto">
        <Row className="justify-content-center">
          <Col md="8">
            <CardGroup>
              <Card className="p-4">
                <CardBody>
                  <Form>
                    <h1>Login</h1>
                    <p className="text-muted">Sign In to your account</p>
                    <InputGroup className="mb-3">
                      <InputGroup.Text>
                        <CiUser />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="Username"
                        autoComplete="username"
                        onChange={(e) => setUsername(e.target.value)}
                      />
                    </InputGroup>
                    <InputGroup className="mb-4">
                      <InputGroup.Text>
                        <CiLock />
                      </InputGroup.Text>
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        autoComplete="current-password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </InputGroup>
                    <Row>
                      <Col xs="6">
                        <Button
                          className="px-4 login-btn"
                          disabled={hasClicked}
                          onClick={loginClicked}
                        >
                          Login
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
              <Card className="text-black icon-div py-5">
                <CardBody className="text-center">
                  <Image src={trykeIcon} height={"200px"} width={"200px"} />
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
        {errorText !== "" && (
          <div className="login-alert-div">
            <Alert className="login-alert" variant="danger">
              {errorText}
            </Alert>
          </div>
        )}
      </Container>
    </div>
  );
};

export default Login;
