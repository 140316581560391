// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/login_bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-main-div {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.icon-div {
    background-color: #FFED38;
    color: #000000;
}

.login-btn {
    color: black;
    background-color: #FFED38;
    border-color: black;
}

.login-btn:hover {
    color: black;
    background-color: #BBAA20;
    border-color: black;
}

.login-alert-div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-alert {
    position: fixed;
    bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/views/Login/Login.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,yDAAgD;IAChD,4BAA4B;IAC5B,sBAAsB;IACtB,2BAA2B;AAC/B;;AAEA;IACI,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,SAAS;AACb","sourcesContent":[".login-main-div {\n    height: 100vh;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-image: url(../../assets/login_bg.png);\n    background-repeat: no-repeat;\n    background-size: cover;\n    background-position: center;\n}\n\n.icon-div {\n    background-color: #FFED38;\n    color: #000000;\n}\n\n.login-btn {\n    color: black;\n    background-color: #FFED38;\n    border-color: black;\n}\n\n.login-btn:hover {\n    color: black;\n    background-color: #BBAA20;\n    border-color: black;\n}\n\n.login-alert-div {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.login-alert {\n    position: fixed;\n    bottom: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
