exports.convertMillisecondsToMinutes = (milliseconds) => {
    return Math.floor(milliseconds / 60000);
};


exports.parseTimestampInMalaysiaTime = (timeStamp) => {
    const date = new Date(timeStamp);
    const options = { timeZone: 'Asia/Kuala_Lumpur', hour12: true };
    const formattedTime = date.toLocaleTimeString('en-MY', options);

    return formattedTime;
};