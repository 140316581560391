import axios from 'axios';
import React, { createContext, useState, useContext, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

    const [authData, setAuthData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchSessionData = async () => {
            const token = sessionStorage.getItem('token');
            if (token) {
                try {
                    const BASE_URL = process.env.REACT_APP_BASE_URL;
                    const response = await axios.get(`${BASE_URL}getSession`, {
                        headers: {
                            "x-access-token": token
                        }
                    });
                    setAuthData(response.data);
                } catch (error) {
                    console.log(error);
                    sessionStorage.removeItem('token');
                }
            }
            setLoading(false);
        };
        fetchSessionData();
    }, []);

    const login = (data) => {
        setAuthData(data);
        sessionStorage.setItem('token', data.token);
    };

    const logout = () => {
        setAuthData(null);
        sessionStorage.removeItem('token');
    };

    return (
        <AuthContext.Provider value={{ authData, login, logout, loading }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
