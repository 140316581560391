import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './views/Login/Login';
import ErrorPage from './pages/error-page';
import PrivateRoute from './components/PrivateRoute';
import Monitoring from './views/Monitoring/Monitoring';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/monitoring",
    element: (
      <PrivateRoute>
        <Monitoring />
      </PrivateRoute>
    ),
    errorElement: <ErrorPage />,
  },
]);

function App() {
  return (
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  );
}

export default App;
