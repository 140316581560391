import React, { useEffect, useState } from "react";
import "./Monitoring.css";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import MapComponent from "../../components/MapComponent";
import HeaderNav from "../../components/HeaderNav";
import { Spinner } from "react-bootstrap";
import MonitoringInfoCards from "../../components/MonitoringInfoCards";
import { convertMillisecondsToMinutes, parseTimestampInMalaysiaTime } from "../../util/sharedFunctions";

const Monitoring = () => {

  const navigate = useNavigate();
  const SSE_RETRY_MS = 150000; // 2.5 minutes in milliseconds
  const { authData, loading } = useAuth();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [zones, setZones] = useState([]);
  const [city, setCity] = useState("NIL");
  const [accountType, setAccountType] = useState("NIL");
  const [stations, setStations] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [inUseVehicles, setInUseVehicles] = useState([]);
  // const [isConnecting, setIsConnecting] = useState(false);
  const [lastUpdateDuration, setLastUpdateDuration] = useState(0);
  const [lastTimestamp, setLastTimestamp] = useState(new Date().getTime());
  const [lastUpdatedAt, setLastUpdatedAt] = useState(`Last updated at ${parseTimestampInMalaysiaTime(new Date().getTime())}`);
  const [mapCenter, setMapCenter] = useState({
    lat: -1,
    lng: -1,
  })
  const [isInitial, setIsInitial] = useState(true);
  const [isShowStations, setIsShowStations] = useState(true);
  const [isShowVehicles, setIsShowVehicles] = useState(true);
  const [isShowInUseVehicles, setIsShowInUseVehicles] = useState(true);

  useEffect(() => {
    if (!loading && !authData) {
      navigate("/");
    }
  }, [authData, loading, navigate]);

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    let cityValue = city;
    let accountTypeValue = accountType;
    let eventSource;
    let retryTimeout;

    if (authData && authData.city) {
      cityValue = authData.city;
      setAccountType(authData.accountType)
      setCity(cityValue);
    }

    const createEventSource = () => {
      // setIsConnecting(true);
      // console.log("Establishing SSE connection...");
      eventSource = new EventSource(`${BASE_URL}sentry/monitoring/data?token=${token}&city=${cityValue}&type=${accountTypeValue}`);

      eventSource.onopen = () => {
        // console.log("SSE connection opened");
        // setIsConnecting(false);
      };

      eventSource.onmessage = (event) => {
        const parsedData = JSON.parse(event.data);

        const currentTimestamp = new Date().getTime();
        setLastUpdateDuration(convertMillisecondsToMinutes(currentTimestamp - lastTimestamp))
        setLastTimestamp(currentTimestamp)
        const updateTimeMessage = `Last updated at ${parseTimestampInMalaysiaTime(currentTimestamp)}`;
        setLastUpdatedAt(updateTimeMessage)

        if (parsedData.error) {
          console.error("Server sent an error:", parsedData.error);
        } else {
          setZones(parsedData.zones);
          setStations(parsedData.stations);
          setVehicles(parsedData.vehicles);
          const vehiclesInUse = parsedData.vehicles.filter((vehicle) => vehicle.status === "InUse");
          if (vehiclesInUse && vehiclesInUse.length > 0) {
            setInUseVehicles(vehiclesInUse);
          }
          setMapCenter(parsedData.mapCenter);
        }
      };

      eventSource.onerror = (error) => {
        console.error("SSE connection error:", error);
        if (eventSource.readyState === EventSource.CLOSED) {
          console.log("SSE connection was closed. Attempting to reconnect...");
          eventSource.close();
          // setIsConnecting(true);
          retryTimeout = setTimeout(createEventSource, SSE_RETRY_MS);
        }
      };
    };

    if (isInitial && city !== "NIL") {
      createEventSource();
      setIsInitial(false)
    }

    return () => {
      if (eventSource) {
        // console.log("Client-side: Closing SSE connection...");
      }
      if (retryTimeout) {
        clearTimeout(retryTimeout);
      }
      // setIsConnecting(false);
    };

  }, [BASE_URL, authData, city, isInitial, lastTimestamp, accountType]);

  if (loading) {
    return (
      <div className="monitoring-loading-div">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className="monitoring-main-div">
      <HeaderNav />
      <div className="monitoring-info-cards">
        <MonitoringInfoCards
          city={city}
          lastUpdatedAt={lastUpdatedAt}
          stationCount={stations.length}
          vehicleCount={vehicles.length}
          isShowStations={isShowStations}
          isShowVehicles={isShowVehicles}
          setIsShowStations={setIsShowStations}
          setIsShowVehicles={setIsShowVehicles}
          lastUpdateDuration={lastUpdateDuration}
          vehicleInUseCount={inUseVehicles.length}
          isShowInUseVehicles={isShowInUseVehicles}
          setIsShowInUseVehicles={setIsShowInUseVehicles}
        />
      </div>
      <div className="map-component">
        <MapComponent
          zones={zones}
          stations={stations}
          vehicles={vehicles}
          mapCenter={mapCenter}
          inUseVehicles={inUseVehicles}
          isShowStations={isShowStations}
          isShowVehicles={isShowVehicles}
          isShowInUseVehicles={isShowInUseVehicles}
        />
      </div>
    </div>
  );
};

export default Monitoring;
