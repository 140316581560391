import React from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  Polygon,
} from "@react-google-maps/api";
import inUseVehicleIcon from "../assets/vehicleMarkerInuse.png";
import availableVehicleIcon from "../assets/vehicleMarkerAvailable.png";
import opsPickUpVehicleIcon from "../assets/vehicleMarkerOpsPickup.png";
import StationCluster from "./StationCluster";

const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;

const MapComponent = ({
  stations,
  zones,
  vehicles,
  mapCenter,
  inUseVehicles,
  isShowStations,
  isShowVehicles,
  isShowInUseVehicles,
}) => {
  const mapContainerStyle = {
    height: "100vh",
    width: "100%",
  };

  const zoneOnLoad = (polygon) => {
    polygon.setOptions({ fillColor: "#90EE90", strokeColor: "#28A745" });
  };

  return (
    <LoadScript
      id="script-loader"
      googleMapsApiKey={GOOGLE_MAP_KEY}
      language="en"
      region="us"
    >
      <GoogleMap
        id="googleHeatMap"
        mapContainerStyle={mapContainerStyle}
        center={mapCenter}
        zoom={14}
      >
        {/* STATION */}
        {isShowStations && stations.length > 0 && (
          <StationCluster stations={stations} />
        )}
        {/* ZONE */}
        {zones.length > 0 &&
          zones.map((zone, index) => (
            <React.Fragment key={index}>
              <Polygon
                strokeOpacity={0.8}
                strokeWeight={2}
                fillOpacity={0.35}
                paths={zone.zoneBoundaries}
                onLoad={zoneOnLoad}
              />
            </React.Fragment>
          ))}
        {/* TOTAL VEHICLES >> Available and Ops_Pickup */}
        {vehicles.length > 0 &&
          isShowVehicles &&
          vehicles
            .filter(
              (vehicle) =>
                vehicle.status === "Available" ||
                vehicle.status === "Ops_Pickup"
            )
            .map((vehicle, index) => (
              <React.Fragment key={index}>
                <Marker
                  position={{
                    lat: parseFloat(vehicle.lat),
                    lng: parseFloat(vehicle.lng),
                  }}
                  title={
                    "Vehicle ID: " +
                    vehicle.vehicleId +
                    "\n" +
                    "Vehicle Status: " +
                    vehicle.status
                  }
                  icon={
                    vehicle.status === "Available"
                      ? availableVehicleIcon
                      : opsPickUpVehicleIcon
                  }
                />
              </React.Fragment>
            ))}
        {/* IN USE VEHICLES */}
        {inUseVehicles.length > 0 &&
          isShowInUseVehicles &&
          inUseVehicles.map((vehicle, index) => (
            <React.Fragment key={index}>
              <Marker
                position={{
                  lat: parseFloat(vehicle.lat),
                  lng: parseFloat(vehicle.lng),
                }}
                title={
                  "Vehicle ID: " +
                  vehicle.vehicleId +
                  "\n" +
                  "Vehicle Status: " +
                  vehicle.status
                }
                icon={inUseVehicleIcon}
              />
            </React.Fragment>
          ))}
      </GoogleMap>
    </LoadScript>
  );
};

export default MapComponent;
