import { Button, Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { CiLogout } from "react-icons/ci";
import { FaUserCheck } from "react-icons/fa";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import trykeTextLogo from "../assets/trykeTextLogo.png";

function HeaderNav() {
  const navigate = useNavigate();
  const { logout } = useAuth();

  const logoutClicked = () => {
    logout();
    navigate("/");
  };

  const navBarStyle = {
    backgroundColor: "#FFED38",
  };

  return (
    <Navbar expand="lg" style={navBarStyle}>
      <Container>
        <Row className="justify-content-start">
          <Col xs lg="2">
            <Navbar.Brand href="#home">
              <img src={trykeTextLogo} height={"50px"} width={"150px"} alt="" />
            </Navbar.Brand>
          </Col>
        </Row>
        <Row>
          <Col style={{padding: "0px"}}>
            <Button variant="outline-success" disabled>
              <FaUserCheck /> 
            </Button>
          </Col>
          <Col style={{padding: "0px", marginLeft: "5px"}}>
            <Button variant="outline-danger" onClick={logoutClicked}>
              <CiLogout />
            </Button>
          </Col>
        </Row>
      </Container>
    </Navbar>
  );
}

export default HeaderNav;
